@import '../../scss/library';

/***** TYPOGRAPHY *****/

.footer-link {
  @include link;
  margin-right: 2em;
  &:last-child {
    margin-right: 0;
  }
}

.signature {
  @include link;
}

/***** ICONS *****/

.social-link {
  cursor: pointer;
  color: $black-color;
  margin-right: 2em;
  &:last-child {
    margin-right: 0;
  }
}

/***** BUTTON *****/

/***** LAYOUT *****/

.footer {
  background: white;
  text-align: center;
  padding: 4em 0 1em;
  font-size: 0.75rem;
}

.social-links-div {
  margin: 2em 0;
}
/***** MEDIA QUERIES *****/
