@import "./scss/library";

/***** TYPOGRAPHY *****/

/***** LAYOUT *****/

.app-container {
  background: $primary-color;
  min-height: 100vh;
  overflow: scroll;
  padding: $layout-padding;
}

/***** MEDIA QUERIES *****/

// under 1100px in width
@media (max-width: $layout-breakpoint-large) {
  .app-container {
    padding: 1rem 0 0;
  }
}
