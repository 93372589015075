@import '../../scss/library';

/***** TYPOGRAPHY *****/

.intro-headline {
  @include headline;
}

/***** IMGS *****/

.main-img {
  width: 100%;
  height: 140vh;
  object-fit: cover;

  &-intro {
    @extend .main-img;
    object-position: 50% 100%;
  }
  &-about {
    @extend .main-img;
    height: 120vh;
    object-position: 50% 150%;
  }
  &-contact {
    @extend .main-img;
    height: 100vh;
    object-position: 50% 100%;
  }

  &-faq {
    @extend .main-img;
    height: 100vh;
    object-position: 50% 50%;
  }
}

.intro-secondary-img {
  width: 100%;
  display: block;
}

/***** BUTTON *****/

.intro-button-accent {
  @include button($white-color, $black-color, $black-color);

  &-link {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    @include button($black-color, $white-color, $black-color);
  }
}

/***** LAYOUT *****/

.landing-page {
  @include page-container-layout(false);
  &.padding-lg {
    @include page-container-layout(true);
  }
}

.split-section {
  @include flex(space-between);
  padding-bottom: 1em;
}

.split-main-div {
  order: 2;
  width: 50%;
}

.split-secondary-div {
  @include flex(normal, column);
  width: 50%;
  order: 1;
  padding: 6em 2em;
}

.intro-div {
  @include intro-section-center;

  &-header {
    @extend .intro-div;
    order: 1;
  }
  &-button {
    @extend .intro-div;
    order: 2;
    margin: 3em auto 3.5em;
  }
  &-img {
    @extend .intro-div;
    order: 3;
  }
}

.theme-horizontal-bar {
  display: none;
  border: none;
}

/***** MISC *****/

.ig-iframe {
  margin: 1em auto;
  margin-bottom: 0rem;
  width: 100%;
  min-height: 90vh;
  overflow: hidden;
  padding: 0 3rem;
  border: none;
}

.ig-feed-container {
  position: relative;
}

.ig-cta {
  margin-top: 4rem;
  padding: 0 4.5rem;

  &-header {
    font-size: 2.5rem;
    font-weight: 900;
    letter-spacing: 0.3;
  }

  &-tag {
    font-size: 1.25rem;
  }
}

/***** MEDIA QUERIES *****/

//over 2000px in width
@media (min-width: 2000px) {
  .ig-iframe {
    padding: 0 12rem;
  }

  .ig-cta {
    padding: 0 13.5rem;
  }
}

//over 1500px in width
@media (min-width: 1500px) {
  .ig-iframe {
    padding: 0 10rem;
  }

  .ig-cta {
    padding: 0 11.5rem;
  }
}

// over 900px in height
@media (min-height: 900px) {
  .main-img-intro {
    height: 95vh;
  }

  .main-img-about {
    height: 85vh;
    object-position: 50% 75%;
  }

  .split-secondary-div {
    padding-top: 10em;
  }
}

// under 900px in width
@media (max-width: $layout-breakpoint-medium) {
  .split-section {
    display: block;
    height: initial;
  }

  .split-main-div {
    width: 100%;
    order: 1;
  }

  .main-img-intro,
  .main-img-about,
  .main-img-faq {
    height: 70vh;
  }

  .intro-headline {
    text-align: center;
  }

  .split-secondary-div {
    width: 100%;
    order: 2;
    padding: 2em;
  }

  .intro-div {
    &-header {
      padding-top: 0em;
      order: 1;
    }
    &-button {
      text-align: center;
      order: 3;
      margin: 0;
      align-self: center;
    }
    &-img {
      order: 2;
      margin: 3em auto 3.5em;
    }
  }

  .ig-iframe {
    padding: 0 1rem;
  }

  .ig-cta {
    padding: 0;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;

    &-header {
      font-size: 2.2rem;
    }

    &-tag {
      font-size: 1.125rem;
    }
  }

  .theme-horizontal-bar {
    display: block;
    color: $primary-color;
    background-color: $primary-color;
    height: 0.5rem;
    margin: 0;
  }
}

// under 900px in width and under 825px in height
@media (max-height: 850px) and (max-width: $layout-breakpoint-medium) {
  .main-img-intro {
    height: 65vh;
    object-position: 50% 75%;
  }

  .main-img-about {
    height: 60vh;
    object-position: 50% 50%;
  }

  .main-img-contact {
    height: 65vh;
    object-position: 50% 20%;
  }
}

// under 600px in width
@media (max-width: $layout-breakpoint-small) {
  .landing-page {
    padding-top: 3.1em;
  }

  .ig-iframe {
    padding: 0;
    // min-height: 110vh;
  }

  .ig-cta {
    &-header {
      font-size: 2rem;
    }

    &-tag {
      font-size: 1rem;
    }
  }
}

//under 450px in width
@media (max-width: $layout-breakpoint-phone) {
  .intro-headline {
    font-size: 2rem;
  }

  .intro-div {
    @include intro-section-center(phone);

    &-img {
      margin: 3em auto 3.5em;
    }
  }

  .split-secondary-div {
    padding: 2rem 1rem;
  }
}
