@import '../../scss/library';

/***** TYPOGRAPHY *****/

.faq {
  line-height: 2.25em;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.2px;
  &-question {
    @extend .faq;
    margin-top: 2.25em;
  }
  &-answer {
    @extend .faq;
    font-size: 1rem;
    padding-bottom: 2.25em;
    margin: 0;
  }

  &-question-answer {
    border-bottom: 1px solid $gray-color;
    &:last-child {
      border-bottom: none;
    }
  }
}

.faq-headline {
  @include headline;
}

/***** IMGS *****/

/***** BUTTON *****/

/***** LAYOUT *****/
.faq-page {
  @include page-container-layout(false);
  &.padding-lg {
    @include page-container-layout(true);
  }
}

.faq-div {
  @include intro-section-center;
  &-headline {
    @extend .faq-div;
  }
  &-copy {
    @extend .faq-div;
  }
}
/***** MEDIA QUERIES *****/

// under 600px in width
@media (max-width: $layout-breakpoint-small) {
  .faq-page {
    padding-top: 3.1em;
  }
}

// under 450px in width
@media (max-width: $layout-breakpoint-phone) {
  .faq-headline {
    font-size: 2rem;
  }

  .faq-copy {
    font-size: 1rem;
  }

  .faq-div {
    @include intro-section-center(phone);
  }
}
