@import "../../scss/library";

/***** TYPOGRAPHY *****/
.product {
  text-align: center;
  margin-bottom: 0;

  &-title {
    @extend .product;
    font-weight: 700;
    font-size: 1.25rem;
    margin-top: 1rem;
  }

  &-link {
    @extend .product;
    color: $black-color;
    text-decoration: none;
  }

  &-sold-out-text {
    @extend .product;
    margin-top: 0.5em;
  }
}

/***** IMGS *****/
.product-thumbnail {
  width: 100%;
  object-fit: cover;
}

/***** BUTTON *****/

/***** LAYOUT *****/
.product-container {
  padding: 2em;
  margin-bottom: 4em;
  display: inline-block;
  width: 33%;
}

.shop-container {
  text-align: center;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}
/***** MEDIA QUERIES *****/

// under 900px in width
@media (max-width: $layout-breakpoint-medium) {
  .product-title {
    font-size: 1rem;
  }

  .product-container {
    width: 50%;
    padding: 1em;
  }
}

// under 600px in width
@media (max-width: $layout-breakpoint-small) {
  .product-title {
    font-size: 1rem;
  }

  .product-container {
    width: 50%;
  }
}

// under 450px in width
@media (max-width: $layout-breakpoint-phone) {
  .product-container {
    width: 75%;
    margin-bottom: 2.5em;
  }
}
