@import '../../scss/library';

/***** TYPOGRAPHY *****/
.shop-headline {
  @include headline;
  text-align: center;
  margin-bottom: 1em;
}

.error-message {
  font-size: 1rem;
  margin: 3rem 0;
  text-align: center;
}

/***** IMGS *****/

/***** BUTTON *****/

/***** LAYOUT *****/
.shop-page {
  @include page-container-layout;
  padding: 10.25em 3em;
}

.vacation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-weight: 600;
    font-size: 1.5rem;
  }

  img {
    width: 50%;
  }
}

/***** MEDIA QUERIES *****/

// under 900px in width
@media (max-width: $layout-breakpoint-medium) {
  .shop-headline {
    font-size: 2.5rem;
  }
}

// under 600px in width
@media (max-width: $layout-breakpoint-small) {
  .shop-page {
    padding: 7em 1.5em;

    &.padding-lg {
      padding: 8em 1.5em;
    }
  }
}

// under 450px in width
@media (max-width: $layout-breakpoint-phone) {
  .shop-page {
    padding: 6em 1.5em 2em;

    &.padding-lg {
      padding: 10em 1.5em 2em;
    }
  }

  .shop-headline {
    font-size: 2rem;
  }
}
