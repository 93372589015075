@import "../../scss/library";

/***** TYPOGRAPHY *****/

.banner-text {
  color: $white-color;
  font-size: 1rem;
  letter-spacing: 0.2px;
  margin: 0;
  font-weight: 600;
}
/***** IMGS *****/

/***** BUTTON *****/

/***** LAYOUT *****/

.banner-div {
  background: $accent-color;
  padding: 0.5rem 2rem;
  z-index: 10;
  text-align: center;
}

/***** MEDIA QUERIES *****/

@media (max-width: $layout-breakpoint-small) {
  .banner-text {
    font-size: 0.85rem;
    // white-space: normal;
  }
}
