@import "../../scss/library";

/***** TYPOGRAPHY *****/
.header-title {
  letter-spacing: 0.1px;
  font-size: 2.5rem;
}

.nav-item {
  margin-left: 1.5rem;
  font-size: 1.25rem;
  text-decoration: none;
  color: $black-color;
  font-weight: 700;
  &:hover {
    color: $gray-color;
  }
}

/***** LAYOUT *****/
.header {
  @include flex(space-between);
  padding: $layout-padding;
  align-items: center;
  padding-bottom: 1rem;
  background: $primary-color;
}

.header-combo {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.nav-bar {
  @include flex(space-between);
}

.current-page {
  color: $gray-color;
}

.nav-icon {
  color: $black-color;
  display: none;
  font-size: 1.6rem;
  cursor: pointer;
  width: 22px;

  &-dynamic {
    @extend .nav-icon;
  }
}

/***** SIDE MENU *****/

// sidebar wrapper styles
.bm-menu-wrap {
  position: fixed;
  width: 12rem;
  top: 6.5em;
  height: 100%;
}

// position and sizing of clickable cross button
.bm-cross-button {
  display: none;
}

// color/shape of close button
.bm-cross {
  background: $black-color;
}

// general sidebar styles
.bm-menu {
  background: $primary-color;
  padding: 2.5em 1.5em 0;

  font-size: 1.15em;
  width: 12rem;
}

// individual item
.bm-item {
  display: inline-block;
  outline: none;
  font-size: 1.25rem;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 1rem;
  color: $black-color;
  transition: color 0.2s;

  &:hover {
    color: $gray-color;
  }
}

/***** MEDIA QUERIES *****/

// under 900px in width
@media (max-width: $layout-breakpoint-medium) {
  .nav-bar {
    display: none;
  }

  .nav-icon {
    display: block;
  }
}

// under 600px in width
@media (max-width: $layout-breakpoint-small) {
  .header {
    padding: 1rem 2rem;
  }

  .header-title {
    font-size: 2rem;
  }

  .nav-icon {
    font-size: 1.25rem;
  }

  .bm-menu-wrap {
    top: 6.5em;
  }
}

// under 450px in width
@media (max-width: $layout-breakpoint-phone) {
  .header-title {
    font-size: 1.75rem;
  }

  .header {
    padding: 1rem 1rem;
  }

  .bm-menu-wrap {
    top: 6.5em;
  }
}
