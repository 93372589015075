@import '../../scss/library';

/***** TYPOGRAPHY *****/

.product-page-nav {
  font-size: 1.1rem;
  margin-bottom: 4rem;
  font-weight: 600;
  color: $gray-color;
  max-width: 60rem;
  margin: 0 auto 2em;

  &-link {
    @extend .product-page-nav;
    color: $black-color;
    text-decoration: none;
  }
}

.product-page-info-title {
  font-weight: 800;
  letter-spacing: 0.3px;
  font-size: 1.7rem;
  margin: 0;
  margin-bottom: 1.2em;
}

.product-page-label {
  margin: 0 0 0.5em;
  font-weight: 400;
}

.product-page-price {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1.2em;
}

.product-page-description {
  &-label {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5em;
  }
}

.product-error-message {
  color: red;
  font-weight: 700;
}

.line-through {
  text-decoration: line-through;
}

/***** IMGS *****/

/***** BUTTON *****/

.product-page-add-cart {
  @include button($white-color, $accent-color, $accent-color);
  display: inline-block;
  width: 100%;
  margin-bottom: 1em;
}

/***** FORM *****/

select {
  -webkit-appearance: button;
  -moz-appearance: button;
  padding: 0.75em 1em;
  font-size: 1rem;
  border: 1px solid $gray-color;
  outline: none;
  width: 100%;
  font-family: inherit;
  cursor: pointer;
  margin-bottom: 1.2em;
}

.product-page-quantity-input {
  padding: 0.75em 1rem;
  width: 50%;
  min-width: 4rem;
  max-width: 6rem;
  outline: none;
  height: 2.5rem;
  border: 1px solid $gray-color;
  margin-bottom: 2em;
}

/***** LAYOUT *****/
.product-page {
  @include page-container-layout;
  padding: 8.25em 7em;

  &.padding-lg {
    padding: 10.25em 7em;
  }
}

.product-page-container {
  &-section {
    @include flex(space-between);
    width: 100%;
    padding: 0;
    max-width: 60rem;
    margin: 0 auto;
  }

  &-img {
    width: 55%;
  }

  &-info {
    width: 40%;
    text-align: left;
  }
}

/***** MISC *****/
.out-of-stock {
  opacity: 0.5;

  &-cursor-default {
    cursor: default;
  }
}
/***** MEDIA QUERIES *****/

// under 900px in width
@media (max-width: $layout-breakpoint-medium) {
  .product-page {
    padding: 8em 3em;
  }
}

// under 600px in width
@media (max-width: $layout-breakpoint-small) {
  .product-page {
    padding: 6.5em 3em 2em;

    &.padding-lg {
      padding: 8em 3em 2em;
    }
  }

  .product-page-container-section {
    @include flex(normal, column);
    width: 100%;
    padding: 1em 2em;
  }

  .product-page-container-img,
  .product-page-container-info {
    width: 100%;
    margin-bottom: 1.5em;
  }
}

// under 450 in width
@media (max-width: $layout-breakpoint-phone) {
  .product-page {
    padding: 6em 1.5em 2em;

    &.padding-lg {
      padding: 10em 1.5em 2em;
    }
  }

  .product-page-container-section {
    @include flex(normal, column);
    width: 100%;
    padding: 0;
  }
}
