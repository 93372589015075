/***** THEME COLORS *****/

$primary-color: #a1b7ba;
$accent-color: #476a6f;
$white-color: #ffffff;
$gray-color: #7a7a7a;
$black-color: #141414;

/***** FONTS *****/

$main-font: 'Source Sans Pro', sans-serif;

/***** LAYOUT *****/

$layout-padding: 1rem 4rem 0;

/***** MEDIA QUERIES *****/

$layout-breakpoint-xlarge: 1500px;
$layout-breakpoint-large: 1100px;
$layout-breakpoint-medium: 900px;
$layout-breakpoint-small: 600px;
$layout-breakpoint-phone: 450px;

/***** MIXINS *****/

@mixin flex($spacing: normal, $direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: $spacing;
}

@mixin page-container-layout($withBanner: true) {
  background: $white-color;
  min-height: 86vh;

  @if $withBanner == true {
    padding-top: 6.1em;
  }
  @if $withBanner == false {
    padding-top: 4.1em;
  }
}

@mixin button($text-color, $background, $border) {
  color: $text-color;
  background: $background;
  border: $border solid 5px;
  padding: 0.75em 1.5em;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 0.5px;
  outline: none;
  cursor: pointer;
}

@mixin link {
  text-decoration: none;
  color: $black-color;
  cursor: pointer;
}

@mixin intro-section-center($device: web) {
  margin: 0 auto;
  @if $device == web {
    width: 24rem;
  }
  @if $device == phone {
    width: 18rem;
  }
}

@mixin headline {
  font-size: 3rem;
  font-weight: 900;
  letter-spacing: 0.3px;
  margin: 0;
}

/***** TYPES *****/

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $main-font;
}

h1 {
  margin: 0;
}

input,
select,
textarea {
  border-radius: 0;
  // background: $white-color;
}
